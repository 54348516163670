<template>
  <div id="read-page">
    <reader-page-app-header
      ref="detailAppHead"
      :title="pageData.class ? pageData.class : '课程学习'"
      :isLoading="isLoading"
      v-scroll:#reader-page-scroll="onScroll"
    >
    </reader-page-app-header>
    <v-fade-transition>
      <page-video
        ref="readerVideo"
        class="pvideo"
        v-if="hasVideo"
        :theme="pageData.theme"
        :video="pageData.video"
        :videoHeight="videoHeight"
      ></page-video>
    </v-fade-transition>
    <div
      :style="`background-color: #ffffff; margin-top: ${
        isApp ? systemBarHeight + videoHeight : videoHeight
      }px`"
    >
      <div
        id="reader-page-scroll"
        class="reader-page-scroll show-scroll"
        :style="`height:${
          isApp
            ? screenHeight - systemBarHeight - videoHeight
            : screenHeight - videoHeight
        }px;`"
      >
        <van-pull-refresh
          style="min-height: 100%"
          v-model="isRefreshing"
          @refresh="onRefresh"
        >
          <div class="read-content mt-12">
            <div v-show="!hasVideo" style="height: 60px"></div>

            <div class="content" v-if="pageData && !isEmpty(pageData)">
              <div class="page-tag d-flex align-center">
                <div class="mr-3">
                  <v-icon small class="mr-1">mdi-bookmark</v-icon
                  >{{ pageData.class }}
                </div>
                <div>
                  <v-icon small class="mr-1">mdi-tag</v-icon>{{ pageData.tag }}
                </div>
              </div>
              <div class="page-title mt-6">{{ pageData.title }}</div>
              <div class="page-sub-title mt-3">{{ pageData.subTitle }}</div>
              <div
                class="page-time mt-7 d-flex align-center justify-space-between"
              >
                <div>
                  <v-icon small class="mr-1">mdi-clock-outline</v-icon
                  >{{ pageData.time }}
                </div>
                <div>
                  难度<v-rating
                    class="ml-1"
                    background-color="grey lighten-2"
                    color="warning"
                    hover
                    length="5"
                    readonly
                    x-small
                    size="1"
                    dense
                    :value="pageData.difficulty"
                  ></v-rating>
                </div>
              </div>
              <v-img
                v-show="pageData && pageData.img"
                class="mt-7 rounded"
                height="150px"
                :src="pageData.img"
              >
                <template v-slot:placeholder>
                  <v-row
                    style="background-color: #6d789d"
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <v-fade-transition>
              <page-lead
                v-if="pageData && pageData.leader"
                v-show="showExplain"
                :theme="pageData.theme"
                :leader="pageData.leader"
              ></page-lead>
            </v-fade-transition>
            <v-fade-transition>
              <page-explain
                v-if="pageData && pageData.textSimple"
                v-show="showExplain"
                :theme="pageData.theme"
                :text="pageData.textSimple"
              ></page-explain>
            </v-fade-transition>

            <page-passage
              v-if="pageData.textCore && !isEmpty(pageData.textCore)"
              :theme="pageData.theme"
              :showExplain="showExplain"
              :passage="pageData.textCore"
              @searchWord="searchWord"
            ></page-passage>

            <v-fade-transition>
              <page-words
                @searchWord="searchWord"
                v-show="showExplain"
                v-if="pageData.words && !isEmpty(pageData.words)"
                :words="pageData.words"
                :theme="pageData.theme"
                :panel="panel"
              ></page-words>
            </v-fade-transition>

            <page-author
              v-if="pageData && !isEmpty(pageData)"
              :theme="pageData.theme"
              :author="pageData.author"
            ></page-author>

            <page-bingo
              v-if="pageData && !isEmpty(pageData)"
              :theme="pageData.theme"
              :bingo="pageData.bingo"
              :rate="pageData.rate"
            >
            </page-bingo>

            <div class="footer-takeplace"></div>
          </div>
        </van-pull-refresh>
      </div>
      <reader-page-word-search
        ref="wordSearch"
        :search-btn-color="
          pageData && !isEmpty(pageData) && pageData.theme
            ? pageData.theme.darkColor
            : '#909090'
        "
      ></reader-page-word-search>
      <reader-page-app-fotter
        :key="navId"
        ref="readerFooter"
        :author="pageData.author"
        :audio="pageData.audio"
        :showExplain="showExplain"
        :theme="pageData.theme"
        @switchExplain="switchExplain"
      ></reader-page-app-fotter>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate, setSystemColor } from "@/mixin/mixin";
import { isEmpty } from "@/utils/common";
import $api from "@/api/api";
import ReaderPageAppHeader from "@/views/reader/components/readerPageAppHeader";
import ReaderPageAppFotter from "@/views/reader/components/readerPageAppFotter";
import ReaderPageWordSearch from "@/views/reader/components/readerPageWordSearch";
import PageAuthor from "@/views/reader/components/pageAuthor";
import PageBingo from "@/views/reader/components/pageBingo";
import PageVideo from "@/views/reader/components/pageVideo";

export default {
  name: "readPage",
  mixins: [setSystemColor, buttonVibrate],
  components: {
    PageVideo,
    ReaderPageWordSearch,
    ReaderPageAppFotter,
    ReaderPageAppHeader,
    PageBingo,
    PageAuthor,
    PageLead: () => import("@/views/reader/components/pageLead"),
    PageExplain: () => import("@/views/reader/components/pageExplain"),
    PagePassage: () => import("@/views/reader/components/pagePassage"),
    PageWords: () => import("@/views/reader/components/pageWords"),
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      screenHeight: "screenHeight",
      screenWidth: "screenWidth",
    }),
    hasVideo() {
      return (
        this.pageData &&
        !isEmpty(this.pageData) &&
        this.pageData.video &&
        !isEmpty(this.pageData.video)
      );
    },
    videoHeight() {
      if (this.hasVideo) {
        let height = (this.screenWidth / 16) * 9;
        if (!this.$vuetify.breakpoint.xs && height > 300) {
          height = 300;
        }
        height = height - this.scrollTop / 2;
        if (height < 120) {
          height = 120;
        }
        return height;
      }
      return 0;
    },
  },
  data: () => ({
    navId: 0,
    passageId: null,
    needRefresh: false,
    isLoading: true,
    isRefreshing: false,
    firstCreat: true,
    showExplain: true,
    scrollTop: 0,
    panel: [],
    pageData: {},
  }),
  methods: {
    isEmpty,
    loadData() {
      this.isLoading = true;
      let getData = {
        id: this.passageId,
      };
      $api
        .getPassageContent(getData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.pageData = res.data;
            this.openExplain();
            if (this.hasVideo) {
              this.setSystemStatusBarStyle("light");
            }
          } else {
            this.$toast.error(res.msg);
            console.log("获取课程内容失败！", res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.isRefreshing = false;
          this.$nextTick(() => {
            if (this.$refs.readerVideo) {
              this.$refs.readerVideo.initPlayer();
            }
            this.$refs.readerFooter.initPlayer();
          });
        });
    },
    onScroll(e) {
      if (!e || !e.target || !e.target.scrollTop) {
        return;
      }
      setTimeout(() => {
        this.scrollTop = e.target.scrollTop;
        // console.log(this.scrollTop);
      }, 50);
    },
    openExplain() {
      if (
        this.pageData &&
        !isEmpty(this.pageData) &&
        this.pageData.words &&
        !isEmpty(this.pageData.words)
      ) {
        this.panel = [];
        for (let i = 0; i < this.pageData.words.length; i += 1) {
          this.panel.push(i);
        }
      }
    },
    switchExplain() {
      this.showExplain = !this.showExplain;
      this.openExplain();
    },
    searchWord(word) {
      word = word.replace(/[^a-zA-Z]/gi, "");
      if (word.length > 1) {
        this.zzzShort();
        this.$refs.wordSearch.open(word);
      }
    },
    onRefresh() {
      // 调接口
      this.navId += 1;
      this.loadData();
    },
    init() {
      const query = this.$route.query;
      this.passageId = query.id;
      this.$store.dispatch("setShowSysAppTab", false);
      this.$store.dispatch("setAppSystemBarColor", "#ffffff");
      this.setSystemFootColor(this.appThemeConfig.footerBarColer);
    },
  },
  created() {
    console.info("readPage组件被创建");
    this.init();
    this.loadData();
  },
  mounted() {
    console.info("readPage组件被挂载");
  },
  activated() {
    console.info("readPage组件被激活");
    if (this.firstCreat) {
      this.firstCreat = false;
    } else {
      this.init();
      if (this.hasVideo) {
        this.setSystemStatusBarStyle("light");
      }
      if (this.needRefresh) {
        console.info("do refresh ...");
        this.$refs.readerFooter.scrolltop = 0;
        this.scrollTop = 0;
        document.getElementById("reader-page-scroll").scrollTo(0, 0);
        this.onRefresh();
      } else {
        if (this.$refs.wordSearch) {
          this.$refs.wordSearch.close();
        }
        this.$vuetify.goTo(this.scrollTop, {
          container: "#reader-page-scroll",
          duration: 0,
          easing: "easeInOutCubic",
        });
      }
    }
  },
  deactivated() {
    this.$refs.readerFooter.stopAudio();
    this.setSystemStatusBarStyle("dark");
    console.info("readPage组件被暂停");
  },
  watch: {
    $route(to, from) {
      if (from.name === "Reader") {
        return;
      }
      if (from.name !== "wordDetail") {
        this.needRefresh = true;
      } else {
        this.needRefresh = false;
      }
      console.log("needRefresh：", this.needRefresh);
    },
  },
};
</script>

<style lang="scss" scoped>
#read-page {
  height: 100%;
  overflow: hidden;
  .pvideo {
    box-shadow: 2px 2px 12px #303030;
  }

  .reader-page-scroll {
    overflow-y: auto;

    .read-content {
      margin: 0 auto;
      width: 100%;
      min-height: 90vh;
      padding: 0;
      font-family: Arvo, sans-serif;
      @media (min-width: 800px) {
        max-width: 35rem;
      }
      @media (min-width: 1264px) {
        max-width: 45rem;
      }
      @media (min-width: 1904px) {
        max-width: 55rem;
      }

      .content {
        padding: 0 1.5rem;
        text-align: justify;

        .sub-title {
          line-height: 1;
          font-weight: 700;
          letter-spacing: 0.2rem;
        }
        .page-tag {
          font-size: 1rem;
          color: #808080;
        }
        .page-title {
          text-align: left;
          font-family: sans-serif;
          font-weight: 700;
          font-size: 1.3rem;
          letter-spacing: 0.2rem;
          --webkit-transform: scaleY(1.1);
          transform: scaleY(1.1);
        }
        .page-sub-title {
          font-family: sans-serif;
          font-size: 0.85rem;
          letter-spacing: 0.2rem;
          --webkit-transform: scaleY(1.2);
          transform: scaleY(1.2);
        }
        .page-time {
          font-size: 0.9rem;
          > div {
            display: flex;
            align-items: center;
          }
        }
      }

      .footer-takeplace {
        width: 100%;
        height: 9.5rem;
      }
    }
  }
  ::v-deep .v-expansion-panel-header__icon .v-icon.v-icon {
    font-size: 10px;
  }
  ::v-deep .v-divider {
    opacity: 0.5;
  }
}
</style>
