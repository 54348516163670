<template>
  <div class="player-gp">
    <div
      v-show="isApp"
      class="bg"
      :style="`height: ${isApp ? systemBarHeight + 3 : 0}px`"
    ></div>
    <div class="vplayer">
      <div
        id="dplayer-reader"
        class="dplayer-reader rounded-t-xl"
        :style="`height: ${videoHeight - 1}px`"
      ></div>
    </div>
  </div>
</template>

<script>
import DPlayer from "dplayer";
import { mapGetters } from "vuex";
import { setSystemColor } from "@/mixin/mixin";
import { isEmpty } from "@/utils/common";
export default {
  name: "pageVideo",
  mixins: [setSystemColor],
  props: {
    theme: {
      type: Object,
      default: () => ({}),
    },
    video: {
      type: Object,
      default: () => ({}),
    },
    videoHeight: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      screenHeight: "screenHeight",
    }),
  },
  data: () => ({
    dplayer: null,
  }),
  methods: {
    initPlayer() {
      if (isEmpty(this.video)) {
        console.info("无音频");
        return;
      }
      this.dplayer = new DPlayer({
        container: document.getElementById("dplayer-reader"),
        volume: 0.7,
        contextmenu: [
          {
            text: "NOTEVER vPlayer 1.0",
          },
        ],
        video: {
          ...this.video,
        },
      });
    },
  },
  created() {
    // this.setSystemStatusBarStyle("light");
  },
  mounted() {
    // this.initPlayer();
  },
  beforeDestroy() {
    // this.setSystemStatusBarStyle("dark");
  },
};
</script>

<style lang="scss" scoped>
.player-gp {
  background-color: #000000;
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  z-index: 4;
  font-family: sans-serif;
  .bg {
    width: 100%;
  }
  .vplayer {
    width: 100%;
    overflow: hidden;
    padding-bottom: 0px;
    .dplayer-reader {
      min-height: 120px;
    }
  }
}
</style>
<style lang="scss">
.dplayer-setting-speed-item {
  height: 1.3rem !important;
}
.dplayer-setting-item {
  height: 1.3rem !important;
  font-size: 0.8rem !important;
}
</style>
