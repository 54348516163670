<template>
  <div class="app-bar" :style="`top:${isApp ? systemBarHeight : 0}px;`">
    <v-progress-linear
      :active="isLoading"
      :indeterminate="true"
      height="2"
      color="deep-purple accent-1"
    ></v-progress-linear>
    <v-btn class="ml-4 mt-3" fab small color="#ffffffee" @click="goBack">
      <v-icon color="#505050">mdi-arrow-left</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "readerPageAppHeader",
  mixins: [buttonVibrate],
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "课程学习",
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appThemeConfig: "appThemeConfig",
      screenHeight: "screenHeight",
    }),
  },
  data: () => ({}),
  methods: {
    goIndex() {
      this.zzzShort();
      this.$router.replace("/");
    },
    goBack() {
      this.zzzShort();
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  position: absolute;
  left: 0;
  z-index: 5;
  height: 2px;
  width: 100%;
}
</style>
