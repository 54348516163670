<template>
  <div class="content my-11">
    <v-card
      dark
      class="mx-auto"
      :color="theme.lightColor"
      :style="`background-image: linear-gradient(to right top,${theme.midColor}aa,${theme.midColor}dd,${theme.darkColor}88);`"
    >
      <v-card-title class="mx-2 d-flex align-end">
        <v-icon large left>fas fa-quote-left </v-icon>
        <span class="teacher-title ml-2">Author</span>
      </v-card-title>

      <v-card-text class="px-6 my-0 py-2 teacher-desc">{{
        author.desc
      }}</v-card-text>

      <v-card-actions>
        <v-list-item class="grow">
          <v-list-item-content style="min-width: 10rem">
            <v-list-item-title
              v-show="author.teacher && !isEmpty(author.teacher)"
              >主讲：{{ author.teacher }}</v-list-item-title
            >
            <span class="mt-2 author-info">
              <span v-show="author.name && !isEmpty(author.name)" class="mr-2"
                >作者：{{ author.name }}</span
              >
              <span v-show="author.audio && !isEmpty(author.audio)" class="mr-2"
                >音频：{{ author.audio }}
              </span>
            </span>
            <span
              class="mt-1 author-info"
              v-show="author.from && !isEmpty(author.from)"
              >来源：{{ author.from }}</span
            >
          </v-list-item-content>

          <v-avatar class="ml-4 elevation-6">
            <v-img
              style="background-color: #eeeeee"
              alt=""
              :src="author.avator"
            ></v-img>
          </v-avatar>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { isEmpty } from "@/utils/common";

export default {
  name: "pageAuthor",
  props: {
    theme: {
      type: Object,
      default: () => ({}),
    },
    author: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    isEmpty,
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 1.5rem;
  text-align: justify;
  font-family: sans-serif;
  .teacher-title {
    letter-spacing: 0.1rem;
    font-weight: 700;
  }
  .teacher-desc {
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    word-break: keep-all;
    line-height: 1.5;
  }
  .author-info {
    font-size: 0.8rem;
    opacity: 0.8;
  }
}
</style>
