<template>
  <div class="footer" v-scroll:#reader-page-scroll="onScroll">
    <div class="footer-content">
      <v-fade-transition>
        <div
          class="audio-player rounded-lg"
          v-show="navItem.indexOf(0) > -1 && !isEmpty(audio)"
        >
          <div id="aplayer-reader" class="vue-plarer rounded-lg"></div>
        </div>
      </v-fade-transition>
      <v-scroll-y-transition>
        <v-bottom-navigation
          v-show="!hideFooter"
          horizontal
          class="justify-space-between footbar"
        >
          <v-btn text :disabled="scrolltop < 5" @click="goTop">
            <v-icon>mdi-chevron-double-up</v-icon>
          </v-btn>
          <v-btn-toggle
            v-model="navItem"
            tile
            :color="theme.darkColor"
            group
            multiple
            dense
            borderless
            class="py-0 my-0 mr-1"
          >
            <v-btn v-show="!isEmpty(audio)" class="ma-0" @click="switchAudio">
              <span>音频</span>
              <v-icon small class="mr-1">mdi-archive-music</v-icon>
            </v-btn>
            <v-btn class="ma-0" @click="switchExplain">
              <span>解析</span>
              <v-icon small class="mr-1">mdi-book-open-page-variant</v-icon>
            </v-btn>
            <v-btn class="ma-0" @click="switchLike">
              <span>收藏</span>
              <v-icon small class="mr-1">mdi-heart</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-bottom-navigation>
      </v-scroll-y-transition>
    </div>
  </div>
</template>

<script>
import APlayer from "APlayer";
import { buttonVibrate } from "@/mixin/mixin";
import { isEmpty } from "@/utils/common";

export default {
  name: "readerPageAppFotter",
  props: {
    showExplain: {
      type: Boolean,
      default: true,
    },
    audio: {
      type: Object,
      default: () => ({}),
    },
    author: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({
        lightColor: "#f7f6ff",
        midColor: "#a8a1d5",
        darkColor: "#5f21bd",
      }),
    },
  },
  mixins: [buttonVibrate],
  data: () => ({
    navItem: [0],
    scrolltop: 0,
    suddenScroll: false,
    hideFooter: false,
    aplayer: null,
  }),
  methods: {
    isEmpty,
    switchAudio() {
      this.zzzShort();
      this.stopAudio();
    },
    switchExplain() {
      this.zzzShort();
      this.$emit("switchExplain");
      this.suddenScroll = true;
    },
    switchLike() {
      this.zzzShort();
    },
    onScroll(e) {
      if (!e || !e.target || !e.target.scrollTop) {
        return;
      }
      setTimeout(() => {
        let newScrollTop = e.target.scrollTop;
        if (newScrollTop - this.scrolltop > 5 && !this.suddenScroll) {
          this.hideFooter = true;
        }
        if (newScrollTop - this.scrolltop < -5) {
          this.hideFooter = false;
        }
        this.scrolltop = newScrollTop;
        this.suddenScroll = false;
      }, 200);
    },
    playAudio() {
      if (this.aplayer) {
        this.aplayer.play();
      }
    },
    stopAudio() {
      if (this.aplayer) {
        this.aplayer.pause();
      }
    },
    goTop() {
      this.zzzShort();
      this.$vuetify.goTo(0, {
        container: "#reader-page-scroll",
        duration: 800,
        easing: "easeInOutCubic",
      });
    },
    initPlayer() {
      if (isEmpty(this.audio)) {
        console.info("无音频");
        return;
      }
      let audio = {
        ...this.audio,
        artist: this.author.audio,
      };
      console.info("初始化播放器");
      this.aplayer = new APlayer({
        container: document.getElementById("aplayer-reader"),
        // mini: true,
        // fixed: true,
        autoplay: false,
        theme: this.theme.midColor,
        loop: "one",
        // order: "list",
        preload: "metadata",
        volume: 0.7,
        mutex: true,
        audio: [audio],
      });
    },
  },
  created() {
    if (this.showExplain) {
      this.navItem.push(1);
    }
  },
  mounted() {
    // this.initPlayer();
  },
  beforeDestroy() {
    if (this.aplayer) {
      this.aplayer.destroy();
    }
  },
  watch: {
    showExplain(val) {
      if (val) {
        this.navItem.push(1);
      } else {
        this.navItem = this.navItem.filter((item) => {
          return item !== 1;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  .footer-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    ::v-deep .theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
      color: rgba(0, 0, 0, 0.5) !important;
    }
  }

  .audio-player {
    margin: 0;
    //width: 18rem;
    //height: 5rem;
    //background-color: #d1f1a9;
    display: flex;
    align-items: center;
    .vue-plarer {
      margin: 0.8rem;
      width: 100%;
      box-shadow: 1px 1px 8px #50505050;
    }
  }
}
</style>
