<template>
  <van-popup
    @close="close"
    closeable
    v-model="show"
    round
    position="bottom"
    class="pop-up"
    close-on-popstate
    overlay-class="mask-overlay"
  >
    <v-progress-linear
      :active="isLoading"
      :indeterminate="true"
      height="2"
      absolute
      top
      color="deep-purple accent-1"
      style="z-index: 5"
    ></v-progress-linear>

    <div v-show="false">
      <audio
        ref="pronounce"
        v-if="!isEmpty(detail) && detail.audio"
        preload="auto"
        :src="detail.audio"
      />
    </div>
    <div class="word-detail">
      <div class="word-title mt-10">{{ word }}</div>
      <transition name="fade">
        <div v-if="!isEmpty(detail)">
          <div
            class="menu d-flex"
            v-show="!isEmpty(detail)"
            style="margin-left: -0.5px"
          >
            <van-tag
              class="tag"
              size="large"
              v-show="!isEmpty(detail) && detail.phonetic"
              >/ {{ detail.phonetic }} /
            </van-tag>
            <v-btn
              v-show="!isEmpty(detail) && detail.audio"
              class="ml-3"
              fab
              plain
              x-small
              elevation="0"
              color="#ffffff"
              :disabled="isPlaying"
              @click="playAudio"
            >
              <v-icon color="blue-grey darken-2">mdi-account-voice</v-icon>
            </v-btn>

            <v-fade-transition>
              <v-btn
                v-show="!isEmpty(detail)"
                class="ml-auto mr-0"
                :loading="likeBtnLoading"
                @click="likeWord"
                icon
              >
                <v-icon :color="isInWordTable ? 'deep-orange lighten-2' : ''"
                  >mdi-heart</v-icon
                >
              </v-btn>
            </v-fade-transition>
          </div>
          <v-divider v-show="!isEmpty(detail)"></v-divider>
          <div
            class="transation"
            v-show="!isEmpty(detail) && detail.translation"
            v-html="formatTranslate(detail.translation)"
          ></div>
          <v-btn
            v-show="!isEmpty(detail)"
            outlined
            class="mt-10 go-btn"
            small
            @click="goDictionary"
            :color="darkColor"
          >
            在词典中查看 —&gt;
          </v-btn>
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div class="empty" v-show="isEmpty(detail) && isLoading">
        <v-skeleton-loader
          class="px-3"
          elevation="0"
          :boilerplate="false"
          type="table-heading"
        ></v-skeleton-loader>
        <v-divider></v-divider>
        <v-skeleton-loader
          class="px-3 py-0 mb-8"
          elevation="0"
          :boilerplate="false"
          type="list-item-three-line,card-heading"
        ></v-skeleton-loader>
      </div>
    </transition>
    <v-dialog v-model="deleteConfirmDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> 确认从单词本移除此单词？ </v-card-title>
        <v-card-text
          >从你的单词本移除该单词意味着将清空此单词的所有学习记录！</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteConfirmDialog = false"
          >
            取消
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete"
            :loading="deleteLoading"
          >
            确认移除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </van-popup>
</template>

<script>
import { Tag } from "vant";
import { Popup } from "vant";
import { isEmpty } from "@/utils/common";
import { buttonVibrate } from "@/mixin/mixin";
import $api from "@/api/api";
import { likeWord } from "@/mixin/wordTableMixin";
export default {
  name: "readerPageWordSearch",
  mixins: [buttonVibrate, likeWord],
  components: {
    VanPopup: Popup,
    vanTag: Tag,
  },
  props: {
    darkColor: {
      type: String,
      default: "#909090",
    },
  },
  data: () => ({
    show: false,
    word: "",
    isLoading: false,
    isPlaying: false,
    playInterval: null,
    deleteConfirmDialog: null,
    likeBtnLoading: false,
    deleteLoading: false,
    isInWordTable: false,
    detail: {},
  }),
  computed: {
    formatTranslate() {
      return function (text) {
        if (!isEmpty(text)) {
          text = text.replace(/\\n/g, "<br />");
          text = text.replace(/&nbsp;/g, " ");
          return text;
        }
        return "";
      };
    },
  },
  methods: {
    isEmpty,
    close() {
      this.show = false;
      this.isLoading = false;
      this.isPlaying = false;
      this.deleteConfirmDialog = null;
      this.likeBtnLoading = false;
      this.deleteLoading = false;
      this.isInWordTable = false;
      this.detail = {};
    },
    open(word) {
      this.word = word;
      this.isLoading = true;
      this.show = true;
      let getData = {
        word: this.word,
      };
      $api
        .quickSearchWord(getData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.detail = res.data;
            this.isInWordTable = res.data.isInWordTable;
            if (isEmpty(this.detail.audio)) {
              this.getAudio();
            }
          } else {
            this.$toast.error(res.msg);
            this.close();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    goDictionary() {
      this.zzzShort();
      this.$router.push({
        path: "/word_detail",
        query: {
          word: this.detail.word,
          id: this.detail.id,
        },
      });
    },
    likeWord() {
      this.zzzShort();
      if (!this.isInWordTable) {
        this.addToWordTable(this.detail.id, this.detail.word);
      } else {
        this.deleteConfirmDialog = true;
      }
    },
    confirmDelete() {
      this.zzzShort();
      this.deleteLoading = true;
      this.deleteToWordTable(this.detail.id, this.detail.word);
    },
    deleteFinally() {
      this.deleteLoading = false;
      this.deleteConfirmDialog = false;
    },
    playAudio() {
      clearInterval(this.playInterval);
      this.playInterval = null;
      this.isPlaying = true;
      this.zzzMid();
      this.$refs.pronounce
        .play()
        .catch((err) => {
          console.log(err);
          this.$toast("播放出错,请重试!");
          let temp = this.detail.audio;
          this.detail.audio = null;
          setTimeout(() => {
            this.detail.audio = temp;
            this.isPlaying = false;
          }, 500);
        })
        .finally(() => {
          this.playInterval = setInterval(() => {
            if (this.$refs.pronounce) {
              if (this.$refs.pronounce.ended) {
                this.isPlaying = false;
                clearInterval(this.playInterval);
              }
            } else {
              this.isPlaying = false;
              clearInterval(this.playInterval);
            }
          }, 250);
        });
    },
    getAudio() {
      if (/^[A-Za-z]+$/g.test(this.word)) {
        let reqData = {
          word: this.word,
        };
        $api
          .getWordPronounce(reqData)
          .then((ret) => {
            let res = ret.data;
            if (res.code === 0 && res.data.sdkAudio) {
              this.detail.audio = res.data.sdkAudio;
              this.$forceUpdate();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-up {
  min-height: 20rem;
  max-height: 90%;
  overflow-y: scroll;

  .word-detail {
    width: 100%;
    height: auto;
    padding: 0 0 5rem 0;

    .go-btn {
      margin: 0 1.8rem 0 1.8rem;
    }

    .menu {
      width: 100%;
      margin: 1.5rem 0.2rem 1rem 0.2rem;
      display: flex;
      align-items: center;
      padding: 0 1.8rem 0 1.8rem;

      .tag {
        height: 1.5rem;
      }
    }

    .word-title {
      padding: 0 1.8rem 0 1.8rem;
      font-family: dosisbold, sans-serif !important;
      font-size: 2rem;
      font-weight: 700;
      color: #303030;
      letter-spacing: 0.08rem;
      max-width: 80%;
      line-height: 1;
      word-wrap: break-word;
      white-space: normal;
      word-break: break-all;
    }

    .transation {
      padding: 0 1.8rem 0 1.8rem;
      font-size: 0.85rem;
      margin-top: 1rem;
      white-space: pre-wrap;
      line-height: 2;
      letter-spacing: 0.06rem;
    }
  }
}
.fade-enter-active {
  transition: all 0.6s ease;
}
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style lang="scss">
.mask-overlay {
  z-index: 4 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.empty {
  width: 100%;
  z-index: 5;
  position: absolute;
  left: 0;
  bottom: 0;
  //background-color: #ffad68;
}
</style>
