<template>
  <div class="rate-group mx-auto my-11 d-flex justify-center align-center">
    <v-rating
      class="my-4"
      empty-icon="mdi-heart-outline"
      full-icon="mdi-heart"
      half-icon="mdi-heart-half-full"
      hover
      background-color="grey lighten-1"
      color="yellow darken-3"
      half-increments
      length="5"
      size="22"
      v-model="rating"
      dense
    ></v-rating>
    <div class="rate-text mb-4">{{ rating }}<span class="ml-1">分</span></div>
    <div class="rate-info mb-12">您对本节课程的评分</div>
    <div class="content">
      <v-btn
        class="my-1 rounded-lg content"
        outlined
        color="#ffa500"
        @click="dobinGo"
        :loading="binGoLoading"
      >
        <v-icon style="margin-right: 4px" v-show="bingo" left dark>
          mdi-check-circle </v-icon
        >{{ bingo ? "已打卡" : "评分并打卡" }}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "pageBingo",
  props: {
    theme: {
      type: Object,
      default: () => ({}),
    },
    bingo: {
      type: Boolean,
      default: false,
    },
    rate: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    rating: 3,
    binGoLoading: false,
  }),
  methods: {
    dobinGo() {},
  },
  created() {
    this.rating = this.rate;
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 1.5rem;
  text-align: justify;
  width: 100%;
}
.rate-group {
  width: 100%;
  flex-direction: column;
  .rate-info {
    font-size: 0.8rem;
    color: #999999;
  }
  .rate-text {
    font-size: 1.5rem;
    > span {
      font-size: 0.9rem;
    }
  }
}
</style>
