import { render, staticRenderFns } from "./readerPageAppFotter.vue?vue&type=template&id=74a59dda&scoped=true&"
import script from "./readerPageAppFotter.vue?vue&type=script&lang=js&"
export * from "./readerPageAppFotter.vue?vue&type=script&lang=js&"
import style0 from "./readerPageAppFotter.vue?vue&type=style&index=0&id=74a59dda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a59dda",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/.pnpm/vuetify-loader@1.7.3_cfe6260f4a77386d492c4e564aa08ca3/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBottomNavigation,VBtn,VBtnToggle,VFadeTransition,VIcon,VScrollYTransition})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/.pnpm/vuetify-loader@1.7.3_cfe6260f4a77386d492c4e564aa08ca3/node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
